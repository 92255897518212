import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {
  Navbar,
  Hero,
  AdverseEffects,
  VitaminDeficiency,
  UsefulProperties,
  Contact,
  ProductContent,
  Qualifications,
} from "./components";
import Thanks from "./pages/Thanks";
import { useState } from "react";
import Footer from "./components/Footer";
function App() {
  const [status, setStatus] = useState(true);
  return (
    <Router>
      <Routes>
        <Route
          path=""
          element={
            <div className="App bg-mainColor">
              <header className="App-header">
                <Navbar />
              </header>
              <main>
                <Hero setStatus={setStatus} />
                <AdverseEffects />
                <VitaminDeficiency />
                <UsefulProperties />
                <Contact darkMode={true} />
                <ProductContent />
                <Qualifications />
                <Contact darkMode={false} />
                <Footer />
              </main>
            </div>
          }
        />
        <Route path="/thanks" element={<Thanks status={status} />} />
      </Routes>
    </Router>
  );
}

export default App;
