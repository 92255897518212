import axios from "axios";

const saveRequest = async (phone, name) =>  {
    try {
        axios.post("https://api.rikki.kz/api/save", {
            utm_source: getParameterByName("utm_source"),
            utm_medium: getParameterByName("utm_medium"),
            utm_campaign: getParameterByName("utm_campaign"),
            utm_content: getParameterByName("utm_content"),
            utm_term: getParameterByName("utm_term"),
            web: "endomarine.rikki.kz",
            name: name,
            number: phone
        })
    } catch (e) {
        console.log(e);
    }
}

const getParameterByName = (name) => {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, " "));
}

export default saveRequest;