import React from "react";
import { ModalContact } from "../components";

const Thanks = ({ status }) => {
  return (
    <div>
      <div>
        <ModalContact
          title="Сіздің сұранысыңыз жіберілді!"
          text="Бізге сенім артқандарыңызға рахмет! Біздің мамандар сізбен жақын арада байланысады."
          status={status}
        />
      </div>
    </div>
  );
};

export default Thanks;
