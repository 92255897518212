import React from "react";
import { useTranslation } from "react-i18next";

const Footer = ({}) => {

  const { t } = useTranslation();
  return (
    <>
    <section>
        <div className="footer pt-[35px] pl-[20px] pr-[20px] pb-[14px] mt-[-1px] bg-darkGreen">
          <div className="container">
            <div className="flex justify-between tablet:flex-row flex-col tablet:items-start items-left">
              <div className="block max-w-[255.14px]">
                <a className="phone:flex items-left mr-12 hidden" href="tel:+77470943226">
                    <span className="text-base text-white">+7 747 0943226</span>
                  </a>   
                  <p className="text-base text-white">г.Алматы, Сатпаева 30А<br/>ТОО “UMMED”<br/>210140038360</p>
              </div>
              <div>
              <p className="text-base text-white">{t("documents")}</p>
                <a className="phone:flex items-left mr-12" href="https://docs.google.com/document/d/1VYuZPaxuZLVllR-SRxFrd0eEHfBhX-3HVCf_W8aM7O8/edit?usp=sharing">
                  <span className="text-base text-white">{t("privacy")}</span>
                 </a>   
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
